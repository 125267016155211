import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {
  DebtAddReqBodyInterface,
  DebtDataInterface, DebtDriverDataInterface, DebtDriverSearchInterface, DebtPassengerInterface,
  DebtReqParamsInterface,
  DebtsDataInterface
} from '../interfaces/debt.interface';
import {Observable} from 'rxjs';
import {pluck} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class DebtService {

  constructor(private http: HttpClient) { }


  public getDebtPassengers(reqParams: DebtReqParamsInterface): Observable<DebtsDataInterface> {
    let params = new HttpParams();
    for (const key in reqParams) {
      if ((reqParams[key] || typeof reqParams[key] === 'number') && reqParams[key] !== 'EMPTY') {
        if (key === 'phone_number' && !reqParams[key].startsWith('+380')) {
          continue;
        }
        params = params.append(key, String(reqParams[key]));
      }
    }
    return this.http.get<DebtsDataInterface>(`operator/passenger-overdrafts/records`, {params});
  }
  public searchPassenger(phone: string): Observable<DebtPassengerInterface[]> {
    return this.http.get<DebtPassengerInterface[]>(`operator/passenger/query?phone_number_starts_with=${encodeURIComponent(`+380${phone}`)}`)
      .pipe(pluck('data'));
  }

  public searchDriver(callsign: string, service: string): Observable<DebtDriverDataInterface> {
    const headers = new HttpHeaders({'X-Taxi-Service': service.toString()});
    return this.http.get<DebtDriverDataInterface>(`operator/drivers/search/callsign?callsign=${callsign}`, {headers});
  }

  public addDebtPassenger(body: DebtAddReqBodyInterface): Observable<DebtDataInterface> {
    return this.http.post<DebtDataInterface>('operator/passenger-overdrafts/records', body);
  }

  public cancelledDebtPassenger(id: string): Observable<void> {
    return this.http.post<void>('operator/passenger-overdrafts/cancel', {id});
  }




}
