import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {daysOfWeekModifier} from '../../../call-centre/modules/utils/const';
import {CommissionScale, Tariff} from '../../models/tariff.model';
import {ISalaryModifierDateTimeHelper} from '../../../call-centre/modules/salary-modifier/models';
import {daysOfWeekISO8601} from '../../utils/consts';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {DriverTariffsService, TariffFormsGenerationService} from "../../services";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {catchError, finalize, tap} from "rxjs/operators";
import {throwError} from "rxjs";
import {HandleEventService} from "@global-services/handle-event-service.service";


@UntilDestroy()
@Component({
  selector: 'utax-commission-reduction-tab',
  templateUrl: './commission-reduction.component.html',
  styleUrls: ['./commission-reduction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommissionReductionTabComponent implements OnInit, OnDestroy {

  private _tariff: Tariff;
  @Input()  set tariff(val: Tariff) {
    this._tariff = val;
    this.dataSource = val?.commission_scales.map((scale) => {
      scale.date_helper = this.convertedSRuleToDate(scale.type_time_intervals);
      return scale;
    });
  }
  get tariff(): Tariff {
    return this._tariff;
  }
  public displayedColumns: string[] = ['is_active', 'starting', 'ending', 'value_type', 'type', 'request_type', 'request_visibility', 'commissions'];
  public dataSource: any[] = [];
  public daysOfWeekModifier = daysOfWeekModifier;
  public daysOfWeek;
  public lockedSpinner = false;
  public spinnerId: string;
  private currentTariff: Tariff;



  constructor(private driverTariffsService: DriverTariffsService,
              public tariffFormsGenerationService: TariffFormsGenerationService,
              private handleEventService: HandleEventService,
              private cdr: ChangeDetectorRef
  ) {
    this.daysOfWeek = daysOfWeekISO8601;
  }

  ngOnInit(): void {
    this.tariffFormsGenerationService.removeScale$
      .pipe(tap((id) => {
        this.dataSource = this.dataSource.filter((scale) => scale.id !== id);
        this.tariff.commission_scales = this.dataSource;
        this.dataSource = this.tariff.commission_scales?.map((scale) => {
          scale.date_helper = this.convertedSRuleToDate(scale.type_time_intervals);
          return scale;
        });
        this.handleEventService.openSnackBar('UTAX_DRIVER_TARIFFS_COMMISSION_REDUCTION_SCALE_REMOVED_SUCCESS');
        this.cdr.detectChanges();
      }))
      .subscribe();
    this.tariffFormsGenerationService.updateCreateTariffScale$
      .pipe(tap((s) => {
        const findIndex = this.dataSource.findIndex((el) => el.id === s.scale.id);
        if (findIndex !== -1) {
          this.dataSource[findIndex] = {...this.dataSource[findIndex], ...s.scale};
          if (s.commissions) {
            s.commissions.forEach((c) => {
              const findCommissionIndex = this.dataSource[findIndex].commissions.findIndex((el) => el.id === c.id);
              if (findCommissionIndex !== -1) {
                this.dataSource[findIndex].commissions[findCommissionIndex] = {...this.dataSource[findIndex].commissions[findCommissionIndex], ...c};
                this.tariff.commission_scales = this.dataSource;
              } else {
                this.dataSource[findIndex].commissions.push(c);
              }
            });
          }
          this.dataSource = [...this.dataSource];
        } else {
          this.dataSource.push({...s.scale, commissions: s.commissions || []});
          this.dataSource = [...this.dataSource];
        }
        this.tariff.commission_scales = this.dataSource;
        this.dataSource = this.tariff.commission_scales?.map((scale) => {
          scale.date_helper = this.convertedSRuleToDate(scale.type_time_intervals);
          return scale;
        });
        this.tariffFormsGenerationService.selectedTariffScale$.next(this.dataSource.find((el) => el.id === s.scale.id));
        this.cdr.detectChanges();
      }))
      .subscribe();
    this.tariffFormsGenerationService.removeInsideScale$
      .pipe(
        tap((id) => {
          const currentScale = this.dataSource.find((el) => el.id === this.tariffFormsGenerationService.selectedTariffScale$.value.id);
          if (currentScale) {
            currentScale.commissions = currentScale.commissions.filter((el) => el.id !== id);
          }
          this.tariff.commission_scales = this.dataSource;
          this.dataSource = this.tariff.commission_scales?.map((scale) => {
            scale.date_helper = this.convertedSRuleToDate(scale.type_time_intervals);
            return scale;
          });
          this.cdr.detectChanges();
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private convertedSRuleToDate(sRuleString?: string): ISalaryModifierDateTimeHelper {
    if (this.checkSRule(sRuleString)) {
      const dayOfWeekISO = [this.daysOfWeekModifier[this.daysOfWeekModifier.length - 1], ...this.daysOfWeekModifier];
      dayOfWeekISO.pop();
      const sRuleArr = sRuleString.split(', ')
        .map((sRuleEl) => {
          return sRuleEl.slice(
            sRuleEl.indexOf('{') + 1,
            sRuleEl.indexOf('}'))
            .split(',')
            .map(day => +day);
        }).flat();
      const sRuleDay = this.daysOfWeekModifier.filter(day => sRuleArr.some((el) => el === day.id)).map(el => el.id);
      const sRuleTime = sRuleString.slice(
        sRuleString.lastIndexOf('{') + 1,
        sRuleString.lastIndexOf('}')
      ).split('-');
      return {
        type: 'day',
        days: sRuleDay,
        startDateTime: sRuleTime[0],
        endDateTime: sRuleTime[1],
      };
    }
  }

  private checkSRule(sRuleString?: string) {
    const sRuleArr = sRuleString?.split(', ')
      .map((sRuleEl) => {
        return sRuleEl.slice(
          sRuleEl.lastIndexOf('{') + 1,
          sRuleEl.lastIndexOf('}'))
          .split('-');
      });
    const setSRule = new Set(sRuleArr?.flat()).size;
    return setSRule === 2;
  }

  scrollPage() {

  }

  public changeToggle($event: MatSlideToggleChange, element: Tariff) {
    this.lockedSpinner = true;
    this.spinnerId = element.id;
    if ($event.checked) {
      this.driverTariffsService.enableScale(element.id)
        .pipe(
          catchError((err) => {
            $event.source.checked = false;
            return throwError(err);
          }),
          finalize(() => {
            this.lockedSpinner = false;
            this.spinnerId = '';
            this.cdr.detectChanges();
          }),
          untilDestroyed(this)
        )
        .subscribe();
    } else {
      this.driverTariffsService.disableScale(element.id)
        .pipe(
          catchError((err) => {
            $event.source.checked = true;
            return throwError(err);
          }),
          finalize(() => {
            this.lockedSpinner = false;
            this.spinnerId = '';
            this.cdr.detectChanges();
          }),
          untilDestroyed(this)
        )
        .subscribe();
    }
  }

  public selectCommissionScaleTariff(commissionScale: CommissionScale) {
    this.tariffFormsGenerationService.selectedTariffScale$.next(commissionScale);
  }

  ngOnDestroy(): void {
    this.tariffFormsGenerationService.selectedTariffScale$.next(null);
  }
}
