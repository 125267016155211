import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  IKeyboardSelect, OperatorStatusAndDuration, OperatorStatusNew,
  RemoteWorkRequestStatus, UserModelNew
} from '../../models';
import {
  BalanceStatisticDataModel,
  BalanceStatisticMetaModel,
  BalanceStatisticPeriod,
  balanceStatisticPeriodToOperatorAccountPeriod
} from '../../models/balance.models';
import {map, switchMap, tap} from 'rxjs/operators';
import { BalanceApiService } from '../../services/balance-api.service';
import { OperatorApiService, RemoteWorkService, UserApiService } from '../../services';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { PhoneNumberVerificationDialogComponent } from '../../components';
import { HandleEventService } from '../../../services';
import {Observable, of} from 'rxjs';
import { AuthService } from '../../../auth/services';
import {MyServicesModel} from '../../models/my-services.model';
import {MyServicesApiService} from '../../services/my-services.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {GlobalDataService} from '@global-services/global-data.service';
import {MatSelectChange} from '@angular/material/select';
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {LocalStorageService} from "ngx-webstorage";
import {ChristmasService} from "@global-services/christmas.service";

@UntilDestroy()
@Component({
  selector: 'utax-cabinet-main',
  templateUrl: './cabinet-main.component.html',
  styleUrls: ['./cabinet-main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CabinetMainComponent implements OnInit {

  readonly balanceStatisticPeriod = BalanceStatisticPeriod;
  readonly remoteWorkRequestStatus = RemoteWorkRequestStatus;
  readonly operatorStatus = OperatorStatusNew;


  selectedBalanceStatisticPeriod = BalanceStatisticPeriod.CURRENT;

  user: UserModelNew;
  services: MyServicesModel[];
  balanceStatistic: BalanceStatisticDataModel;
  balanceStatisticMeta: BalanceStatisticMetaModel;
  workStatistic: OperatorStatusAndDuration[];
  workStatisticPaused: number;
  workStatisticTalking: number;
  workStatisticFree: number;
  callsMissedCount: number;
  keyboardLayouts: IKeyboardSelect[] = [
    {name: 'UTAX_CURRENT_HOTKEY_SET', value: 'old'},
    {name: 'UTAX_NEW_HOTKEY_SET', value: 'new'}
  ];
  searchAddressMethods: IKeyboardSelect[] = [
    {name: 'UTAX_CURRENT_SEARCH_METHOD_SET', value: 'old'},
    {name: 'UTAX_NEW_SEARCH_METHOD_SET', value: 'new'}
  ];
  selectedKeyboard$: Observable<string>;
  selectedIsShowBalanceInDisp$: Observable<string>;
  selectedSearchAddressMethod$: Observable<string>;


  authUser$: Observable<any>;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private userApiService: UserApiService,
    private balanceApiService: BalanceApiService,
    private operatorApiService: OperatorApiService,
    private translateService: TranslateService,
    public remoteWorkService: RemoteWorkService,
    private matDialog: MatDialog,
    private handleEventService: HandleEventService,
    private myServices: MyServicesApiService,
    private authService: AuthService,
    private globalDataService: GlobalDataService,
    private localStorageService: LocalStorageService,
    public christmasService: ChristmasService
  ) {}

  ngOnInit(): void {
    this.selectedKeyboard$ = this.globalDataService.isNewKeyboardLayout$
      .pipe(
        map((value) => value ? 'new' : 'old'),
        untilDestroyed(this)
      );
    this.selectedIsShowBalanceInDisp$ = this.globalDataService.isShowBalanceInDisp$
      .pipe(
        map((value) => value ? 'show' : 'hide'),
        untilDestroyed(this)
      );
    this.authUser$ = this.authService.getUser().pipe(
      switchMap((user) => user ? of(user) : this.authService.syncUser())
    );
    this.authService.getUser().subscribe((user) => console.log(user));
    this.userApiService.user$
      .subscribe(user => {
        if (user) {
          this.user = user;
          this.getTaxiServices();
          this.updateBalanceStatistic();
          this.changeDetectorRef.detectChanges();
        }
      });
    this.selectedSearchAddressMethod$ = this.globalDataService.globalState$
      .pipe(
        map(() => 'new'),
        untilDestroyed(this)
      );
  }


  updateStatistic(): void {
    this.callsMissedCount = 0;
    this.workStatisticFree = 0;
    this.workStatisticTalking = 0;
    this.workStatisticPaused = 0;
    this.operatorApiService.getAccountStatisticNew(balanceStatisticPeriodToOperatorAccountPeriod(this.selectedBalanceStatisticPeriod))
      .pipe(
        tap((item) => this.callsMissedCount = item?.missedCallsCount),
        switchMap(() => this.operatorApiService.getAccountWorkStatisticNew(balanceStatisticPeriodToOperatorAccountPeriod(this.selectedBalanceStatisticPeriod)))
      )
      .subscribe(workStatistic => {
        if (workStatistic.length > 0) {
          workStatistic.forEach((option) => {
            if (option?.status === this.operatorStatus.FREE) {
              this.workStatisticFree = option.seconds;
            } else if (option?.status === this.operatorStatus.TALKING) {
              this.workStatisticTalking = option.seconds;
            } else if (option?.status === this.operatorStatus.PAUSED) {
              this.workStatisticPaused = option.seconds;
            }
          });
        }
        this.changeDetectorRef.detectChanges();
      });
  }
  updateBalanceStatistic(): void {
    const operatorAccountPeriod = balanceStatisticPeriodToOperatorAccountPeriod(this.selectedBalanceStatisticPeriod);

    this.balanceApiService.getStatistic(this.selectedBalanceStatisticPeriod)
      .subscribe(balanceStatistic => {
        this.balanceStatistic = balanceStatistic.data;
        this.balanceStatisticMeta = balanceStatistic.meta;
        this.changeDetectorRef.detectChanges();
      });

    // this.operatorApiService.getAccountWorkStatisticNew(operatorAccountPeriod)
    //   .subscribe(workStatistic => {
    //     this.workStatistic = workStatistic;
    //     this.changeDetectorRef.detectChanges();
    //   });
    this.updateStatistic();

  }

  sortQueuePredicate(a, b) {
    if (a?.isActive > b?.isActive) {
      return -1;
    }
    if (a?.isActive === b?.isActive) {
      return 0;
    }
    if (a?.isActive < b?.isActive) {
      return 1;
    }
  }

  getTaxiServices(): void {
    this.myServices.getTaxiServices().subscribe((services) => {
      this.services = services;
    });
  }

  requestPhoneNumberVerification(): void {
    const dialogRef = this.matDialog.open(PhoneNumberVerificationDialogComponent, {
      panelClass: 'phone-number-verification-dialog',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.handleEventService.openSnackBar(this.translateService.instant('PHONE_NUMBER_VERIFICATION_SUCCESS'));
      }
    });
  }

  public changeStateSetting(layout: MatSelectChange, typeSetting: 'keyboardSet' | 'searchAddressMethod' | 'showBalanceInDisp') {
    this.globalDataService.changeGlobalState({[typeSetting]: layout.value})
      .pipe(
        tap(() => {
          if ( typeSetting === 'keyboardSet' ) {
            this.globalDataService.isNewKeyboardLayout$.next(layout.value === 'new');
          } else if (typeSetting === 'showBalanceInDisp') {
            this.globalDataService.isShowBalanceInDisp$.next(layout.value === 'show');
          }
        }),
        untilDestroyed(this)
      ). subscribe();
  }

  changeValentineMood($event: MatSlideToggleChange) {
    this.localStorageService.store('valentineMood', $event.checked ? 'active' : 'inactive');
  }
}
