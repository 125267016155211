import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import {environment} from '@global-environments/environment';
import {IClipboardResponse} from 'ngx-clipboard';
import {OrderClass} from '@global-classes/order.class';

@Component({
  selector: 'utax-order-details-info',
  templateUrl: './order-details-info.component.html',
  styleUrls: ['./order-details-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderDetailsInfoComponent implements OnChanges {
  @Input()
  public order: OrderClass;
  @Output()
  public copyLink: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  public callContactFromOrder: EventEmitter<OrderClass> = new EventEmitter<OrderClass>();
  @Output()
  public callContactDeliveryFromOrder: EventEmitter<OrderClass> = new EventEmitter<OrderClass>();

  environment = environment;
  passengerDetailsOpened = false;
  driverDetailsOpened = false;
  archiveLink = '';

  constructor() {}

  ngOnChanges() {
    if (this.order) {
      this.driverDetailsOpened = false;
      this.archiveLink = `${environment.config.whiteFront}archive/request/${this.order.id}`;
    }
  }

  call(phoneNumber): void {
    window.open('sip:' + phoneNumber, '_self');
  }

  public copied($event: IClipboardResponse) {
    // this.handleEventService.openSnackBar('UTAX_COPY_LINK_IN_BUFFER_SUCCESS');
    this.copyLink.emit(true);
  }
}
