import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PassengerPutInterface} from "../../../../passengers/interfaces/passengers-statistic.interface";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import { finalize, tap} from "rxjs/operators";
import {DebtService} from "../../../../debt-passenger/services/debt.service";

@UntilDestroy()
@Component({
  selector: 'utax-passenger-debt-cancel-modal',
  templateUrl: './passenger-debt-cancel-modal.component.html',
  styleUrls: ['./passenger-debt-cancel-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PassengerDebtCancelModalComponent implements OnInit {

  public disabledApproveButton: boolean;


  constructor(
    public dialogSelf: MatDialogRef<PassengerDebtCancelModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: PassengerPutInterface,
    private debtService: DebtService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
  }

  approve() {
    this.disabledApproveButton = true;
    this.debtService.cancelledDebtPassenger(this.data.id)
      .pipe(
        tap(() => this.dialogSelf.close(true)),
        finalize(() => {
          this.disabledApproveButton = false;
          this.cdr.detectChanges();
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

}
