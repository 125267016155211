import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, UntypedFormArray, UntypedFormGroup, Validators} from "@angular/forms";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {DriverTariffsService, TariffFormsGenerationService} from "../../services";
import {daysOfWeekISO8601} from "../../utils/consts";
import {daysOfWeekModifier} from '../../../call-centre/modules/utils/const';
import {TranslateService} from "@ngx-translate/core";
import {ISalaryModifierDateTimeHelper} from "../../../call-centre/modules/salary-modifier/models";
import * as moment from 'moment';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CommissionScale, Tariff} from "../../models/tariff.model";
import {finalize, mergeMap, switchMap, tap} from "rxjs/operators";
import {forkJoin, Observable, of} from "rxjs";
import {TimezoneService} from "@global-services/timezone.service";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";


@UntilDestroy()
@Component({
  selector: 'utax-commission-reduction-dialog',
  templateUrl: './commission-reduction-dialog.component.html',
  styleUrls: ['./commission-reduction-dialog.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
  ]
})
export class CommissionReductionDialogComponent implements OnInit {

  public tariffScalesForm: FormGroup;
  public daysOfWeek;
  public daysOfWeekModifier;
  public selectedDaysOfWeek = [];

  public commissionScaleInterval: ISalaryModifierDateTimeHelper = {
    days: [1],
    startDateTime: '',
    endDateTime: '',
  };
  disabledSaveButton: boolean;
  public allowSaveScaleIntervals = true;



  constructor(
    private fb: FormBuilder,
    public tariffFormsService: TariffFormsGenerationService,
    public driverTariffsService: DriverTariffsService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA)
    public data: CommissionScale,
    public dialogRef: MatDialogRef<CommissionReductionDialogComponent>,
    private tzService: TimezoneService
  ) {
    this.daysOfWeek = daysOfWeekISO8601;
    this.daysOfWeekModifier = daysOfWeekModifier.map(day => {
      day.value = this.translateService.instant(day?.value);
      return day;
    });
    this.generateForm();
    this.setDefaultValueForm();
  }

  ngOnInit(): void {
    this.tariffScalesForm.valueChanges
      .pipe(tap(() => {
        this.tariffScalesForm.updateValueAndValidity();
      }))
      .subscribe();
  }

  private generateForm(): void {
    this.tariffScalesForm = this.fb.group({
      tariff_plan_id: null,
      starting_date: [null, [Validators.required]],
      starting_time: [null, [Validators.required]],
      ending_date: [null, [Validators.required]],
      ending_time: [null, [Validators.required]],
      value_type: null,
      type: null,
      request_type: null,
      request_visibility: null,
      type_week_start_day: null,
      type_time_intervals: '',
      commissions: this.fb.array([])
    });
  }

  private setDefaultValueForm() {
    this.tariffScalesForm.patchValue({
      tariff_plan_id: this.tariffFormsService.tariff.value.id,
      starting_date: this.data?.starting ? moment.utc(this.data?.starting, 'YYYY-MM-DD HH:mm:ss' ).local().toDate() : null,
      starting_time: this.data?.starting ? moment.utc(this.data?.starting, 'YYYY-MM-DD HH:mm:ss' ).local().format('HH:mm') : null,
      ending_date: this.data?.ending ? moment.utc(this.data?.ending, 'YYYY-MM-DD HH:mm:ss' ).local().toDate() : null,
      ending_time: this.data?.ending ? moment.utc(this.data?.ending, 'YYYY-MM-DD HH:mm:ss' ).local().format('HH:mm') : null,
      value_type: this.data?.value_type || 'all',
      type: this.data?.type || 'current_week',
      request_type: this.data?.request_type || 'all',
      request_visibility: this.data?.request_visibility || 'all',
      type_week_start_day: this.data?.type_week_start_day || 1,
      type_time_intervals: this.data?.type_time_intervals || null
    });
    if (this.data?.commissions) {
      this.data.commissions.forEach((el) => {
        (this.tariffScalesForm.get('commissions') as FormArray).push(this.fb.group(el));
      });
    }
    if (this.data?.type_time_intervals) {
      this.commissionScaleInterval = this.convertedSRuleToDate(this.data.type_time_intervals);
    }
  }

  private convertedSRuleToDate(sRuleString?: string): ISalaryModifierDateTimeHelper {
    if (this.checkSRule(sRuleString)) {
      const dayOfWeekISO = [this.daysOfWeekModifier[this.daysOfWeekModifier.length - 1], ...this.daysOfWeekModifier];
      dayOfWeekISO.pop();
      const sRuleArr = sRuleString.split(', ')
        .map((sRuleEl) => {
          return sRuleEl.slice(
            sRuleEl.indexOf('{') + 1,
            sRuleEl.indexOf('}'))
            .split(',')
            .map(day => +day);
        }).flat();
      const sRuleDay = this.daysOfWeekModifier.filter(day => sRuleArr.some((el) => el === day.id)).map(el => el.id);
      const sRuleTime = sRuleString.slice(
        sRuleString.lastIndexOf('{') + 1,
        sRuleString.lastIndexOf('}')
      ).split('-');
      return {
        type: 'day',
        days: sRuleDay,
        startDateTime: sRuleTime[0],
        endDateTime: sRuleTime[1],
      };
    }
  }

  private checkSRule(sRuleString?: string) {
    const sRuleArr = sRuleString.split(', ')
      .map((sRuleEl) => {
        return sRuleEl.slice(
          sRuleEl.lastIndexOf('{') + 1,
          sRuleEl.lastIndexOf('}'))
          .split('-');
      });
    const setSRule = new Set(sRuleArr.flat()).size;
    return setSRule === 2;
  }

  private convertedDateToSRule(element: ISalaryModifierDateTimeHelper): string {
    return `["~w{${element.days.join()}};t{${element.startDateTime}-${element.endDateTime}}~"]`;
  }
  private checkInterval(element: ISalaryModifierDateTimeHelper) {
    return (this.tariffScalesForm.get('type').value === 'time_interval' || this.tariffScalesForm.get('type').value === 'to_day_end') &&
      element.days.length && moment(element.startDateTime, 'HH:mm').isValid() && moment(element.endDateTime, 'HH:mm').isValid() &&
      moment(element.startDateTime, 'HH:mm').isBefore(moment(element.endDateTime, 'HH:mm'));
  }

  changeSelect($event: any, type: 'days' | 'startDateTime' | 'endDateTime') {
    this.commissionScaleInterval = {...this.commissionScaleInterval, [type]: $event};
    this.tariffScalesForm.get('type_time_intervals').setValue(this.convertedDateToSRule(this.commissionScaleInterval), {emitEvent: true});
    this.tariffScalesForm.markAsDirty();
    this.allowSaveScaleIntervals = (this.tariffScalesForm.get('type').value !== 'time_interval' && this.tariffScalesForm.get('type').value !== 'to_day_end') || this.checkInterval(this.commissionScaleInterval);
  }

  save() {
    this.disabledSaveButton = true;
    const value = this.tariffScalesForm.value;
    const savingObject = {
      id: this.data?.id,
      tariff_plan_id: this.tariffFormsService.tariff.value.id,
      starting: `${moment.utc(moment(`${moment(value.starting_date).format('DD.MM.YYYY')} ${value.starting_time}`, 'DD.MM.YYYY HH:mm')).format('YYYY-MM-DD HH:mm:ss')}`,
      ending: `${moment.utc(moment(`${moment(value.ending_date).format('DD.MM.YYYY')} ${value.ending_time}`, 'DD.MM.YYYY HH:mm')).format('YYYY-MM-DD HH:mm:ss')}`,
      value_type: value.value_type,
      type: value.type,
      request_type: value.request_type,
      request_visibility: value.request_visibility,
      type_week_start_day: value.type_week_start_day,
      type_time_intervals: null,
    };

    if (this.checkInterval(this.commissionScaleInterval)) {
      savingObject.type_time_intervals = this.convertedDateToSRule(this.commissionScaleInterval);
    } else {
      delete savingObject.type_time_intervals;
    }
    if (this.data?.id) {
      this.driverTariffsService.putScale(savingObject)
        .pipe(
          switchMap(this.commissionsSavingFunc),
          untilDestroyed(this),
          finalize(() => {
            this.disabledSaveButton = false;
            this.cdr.detectChanges();
          })
        )
        .subscribe((el) => {
          this.dialogRef.close(el);
        });
    } else {
      delete savingObject.id;
      this.driverTariffsService.postScale(savingObject)
        .pipe(
          mergeMap(this.commissionsSavingFunc),
          untilDestroyed(this),
          finalize(() => {
            this.disabledSaveButton = false;
            this.cdr.detectChanges();
          })
        )
        .subscribe((el) => {
          this.dialogRef.close(el);
        });
    }
  }

  private commissionsSavingFunc = (commissionScale: CommissionScale) => {
    const commissionsValueArrayForSave = this.getCommissionsValue();
    if (commissionsValueArrayForSave.length === 0) {
      return forkJoin({ scale: of(commissionScale)});
    } else {
      const reqs: Array<Observable<any>> = [];
      commissionsValueArrayForSave.forEach((commissionValue, index) => {
        if (commissionValue.id) {
          reqs.push(this.driverTariffsService.putCommission(commissionValue, commissionScale.id));
        } else {
          reqs.push(this.driverTariffsService.postCommission(commissionValue, commissionScale.id));
        }
      });
      return forkJoin({scale: of(commissionScale), commissions: forkJoin(reqs)});
    }
  };


  public get commissionsFormArray(): FormArray {
    return this.tariffScalesForm.get('commissions') as FormArray;
  }

  private getCommissionsValue(): any[] {
    const commissionsValueArrayForSave = [];
    this.commissionsFormArray.controls.forEach((commissionForm: UntypedFormGroup) => {
      if (commissionForm.touched) {
        commissionsValueArrayForSave.push(commissionForm.getRawValue());
      }
    });
    return commissionsValueArrayForSave;
  }


}
