import { Component, OnInit, Input } from '@angular/core';
import {FormBuilder, UntypedFormGroup} from '@angular/forms';
import {daysOfWeekISO8601} from '../../utils/consts';
import {daysOfWeekModifier} from '../../../call-centre/modules/utils/const';
import {ISalaryModifierDateTimeHelper} from "../../../call-centre/modules/salary-modifier/models";
import * as moment from "moment/moment";
import {TariffFormsGenerationService} from "../../services";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'utax-commission-scale',
  templateUrl: './commission-scale.component.html',
  styleUrls: ['./commission-scale.component.scss']
})
export class CommissionScaleComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  public daysOfWeek;
  public daysOfWeekModel = [];
  public from = '00:00';
  public to = '23:59';
  public daysOfWeekModifier = daysOfWeekModifier;
  public comissionScaleInterval: ISalaryModifierDateTimeHelper = {
    days: [1],
    startDateTime: '00:00',
    endDateTime: '23:59',
  };
  constructor(public tariffFormsService: TariffFormsGenerationService) {
    this.daysOfWeek = daysOfWeekISO8601;
    this.daysOfWeekModel = [this.daysOfWeekModifier[0]];
  }

  ngOnInit(): void {
    this.tariffFormsService.setScaleIntervals$
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(() => {
        if (this.form.get('commission_scale_intervals')?.value) {
          this.comissionScaleInterval = this.convertedSRuleToDate(this.form.get('commission_scale_intervals').value);
        } else {
          this.comissionScaleInterval = {
            days: [1],
            startDateTime: '00:00',
            endDateTime: '23:59',
          };
        }
    });
  }


  private convertedSRuleToDate(sRuleString?: string): ISalaryModifierDateTimeHelper {
    if (this.checkSRule(sRuleString)) {
      const dayOfWeekISO = [this.daysOfWeekModifier[this.daysOfWeekModifier.length - 1], ...this.daysOfWeekModifier];
      dayOfWeekISO.pop();
      const sRuleArr = sRuleString.split(', ')
        .map((sRuleEl) => {
          return sRuleEl.slice(
            sRuleEl.indexOf('{') + 1,
            sRuleEl.indexOf('}'))
            .split(',')
            .map(day => +day);
        }).flat();
      const sRuleDay = this.daysOfWeekModifier.filter(day => sRuleArr.some((el) => el === day.id)).map(el => el.id);
      const sRuleTime = sRuleString.slice(
        sRuleString.lastIndexOf('{') + 1,
        sRuleString.lastIndexOf('}')
      ).split('-');
      return {
        type: 'day',
        days: sRuleDay,
        startDateTime: sRuleTime[0],
        endDateTime: sRuleTime[1],
      };
    }
  }

  private checkSRule(sRuleString?: string) {
    const sRuleArr = sRuleString.split(', ')
      .map((sRuleEl) => {
        return sRuleEl.slice(
          sRuleEl.lastIndexOf('{') + 1,
          sRuleEl.lastIndexOf('}'))
          .split('-');
      });
    const setSRule = new Set(sRuleArr.flat()).size;
    return setSRule === 2;
  }

  private convertedDateToSRule(element: ISalaryModifierDateTimeHelper): string {
    return `["~w{${element.days.join()}};t{${element.startDateTime}-${element.endDateTime}}~"]`;
  }
  private checkInterval(element: ISalaryModifierDateTimeHelper) {
    return (this.form.get('commission_scale_type').value === 'time_interval' || this.form.get('commission_scale_type').value === 'to_day_end') &&
    element.days.length && moment(element.startDateTime, 'HH:mm').isValid() && moment(element.endDateTime, 'HH:mm').isValid() &&
      moment(element.startDateTime, 'HH:mm').isBefore(moment(element.endDateTime, 'HH:mm'));
  }

  changeSelect($event: any, type: 'days' | 'startDateTime' | 'endDateTime') {
    this.comissionScaleInterval = {...this.comissionScaleInterval, [type]: $event};
    this.form.get('commission_scale_intervals').setValue(this.convertedDateToSRule(this.comissionScaleInterval), {emitEvent: true});
    this.form.markAsDirty();
    this.tariffFormsService.allowSaveScaleIntervals = (this.form.get('commission_scale_type').value !== 'time_interval' && this.form.get('commission_scale_type').value !== 'to_day_end') || this.checkInterval(this.comissionScaleInterval);
  }

}
