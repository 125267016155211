import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { Commission } from '../models/tariff.model';
import { RegularPayments } from '../models/payment.model';

const driverTariffsUrl = 'operator/tariff-plans';
const listOfTariffsUrl = 'operator/tariff-plans/advanced';
const commissionsUrl = 'operator/tariff-plans/commissions';
const regularPaymentUrl = 'operator/tariff-plans/payments';
const scaleUrl = 'operator/tariff-plans/payments/scales';
const scaleCommissions = 'operator/tariff-plans/commissions/scales';
const periodicBonuses = 'operator/tariff-plans/periodic-bonuses';

@Injectable({
  providedIn: 'root'
})
export class DriverTariffsService {
  constructor(private http: HttpClient) {}

  // tariffs
  getListOfTariffsByService(serviceId: number): Observable<any> {
    return this.http.get(listOfTariffsUrl, { params: { service: `${serviceId}` } }).pipe(pluck('data'));
  }

  postTariff(tariff: any): Observable<any> {
    return this.http.post(driverTariffsUrl, { ...tariff, use_ignore_penalty: false }).pipe(pluck('data'));
  }

  putTariff(tariff: any): Observable<any> {
    return this.http.put(driverTariffsUrl, { ...tariff, ignore_penalty_expression: null }).pipe(pluck('data'));
  }

  deleteTariff(id: string): Observable<any> {
    return this.http.delete(driverTariffsUrl, { params: { id } });
  }

  // bonuses
  getPeriodicBonusesList(id: string): Observable<any> {
    return this.http.get(periodicBonuses, { params: { tariff_plan_id: `${id}` } }).pipe(pluck('data'));
  }

  postPeriodicBonus(bonus: any): Observable<any> {
    console.log('services!!!!!!')
    return this.http.post(periodicBonuses, bonus);
  }

  putPeriodicBonus(bonus: any): Observable<any> {
    return this.http.put(periodicBonuses, bonus);
  }

  deletePeriodicBonus(id: string): Observable<any> {
    return this.http.delete(periodicBonuses, { params: { id } });
  }

  // commissions
  putCommission(commission: Commission, tariffId: string): Observable<any> {
    return this.http
      .put(commissionsUrl, {
        ...commission,
        commission_scale_id: tariffId,
        type: 'percent'
      })
      .pipe(pluck('data'));
  }

  postCommission(commission: Commission, tariffId: string): Observable<any> {
    return this.http
      .post(commissionsUrl, {
        ...commission,
        commission_scale_id: tariffId,
        type: 'percent'
      })
      .pipe(pluck('data'));
  }

  deleteCommission(id: string): Observable<any> {
    return this.http.delete(commissionsUrl, { params: { id } });
  }

  // regular payments
  postRegularPayment(regularPayment: RegularPayments): Observable<any> {
    return this.http.post(regularPaymentUrl, regularPayment).pipe(pluck('data'));
  }

  putRegularPayment(obj: RegularPayments): Observable<any> {
    return this.http.put(regularPaymentUrl, obj).pipe(pluck('data'));
  }

  deleteRegularPayment(paymentId: string): Observable<any> {
    return this.http.delete(regularPaymentUrl, { params: { id: paymentId } });
  }

  // scales
  postActionScale(obj): Observable<any> {
    return this.http.post(scaleUrl, obj).pipe(pluck('data'));
  }

  putActionScale(obj): Observable<any> {
    return this.http.put(scaleUrl, obj).pipe(pluck('data'));
  }

  deleteActionScale(scaleId: string): Observable<any> {
    return this.http.delete(scaleUrl, { params: { id: scaleId } });
  }

  postScale(obj): Observable<any> {
    return this.http.post(scaleCommissions, obj).pipe(pluck('data'));
  }

  putScale(obj): Observable<any> {
    return this.http.put(scaleCommissions, obj).pipe(pluck('data'));
  }

  deleteScale(scaleId: string): Observable<any> {
    return this.http.delete(scaleCommissions, { params: { id: scaleId } });
  }

  enableScale(id: string): Observable<any> {
    return this.http.post(scaleCommissions + '/enable', { id });
  }

  disableScale(id: string): Observable<any> {
    return this.http.post(scaleCommissions + '/disable', { id });
  }

}
