import { ShortcutInput } from 'ng-keyboard-shortcuts';
import { OrderFormService } from '../../../order-form/services';
import { Order } from '../../../interfaces/order.interface';
import { DispOrdersService } from '../../services';
import {Component, OnInit, AfterViewInit, OnDestroy, ChangeDetectorRef, Input} from '@angular/core';
import { Subject } from 'rxjs';
import {filter, takeUntil, tap} from 'rxjs/operators';
import {HandleEventService, OrdersService, WebsocketService} from '../../../services';
import {TelephonySharedService} from '@global-telephony/services/telephony-shared.service';
import {OrderClass} from '@global-classes/order.class';
import {AuthService} from '../../../auth/services';
import {ChatListenerObjectInterface, ChatUserActionInterface} from '../../models/dispatcher';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ChatApiService} from '../../../shared/services';
import {LocationService} from '../../../shared/services/location.service';
import {OperatorCommentService} from "../../../shared/services/operator-comment.service";
import {OperatorCommentApiService} from "../../../shared/services/operator-comment-api.service";

@UntilDestroy()
@Component({
  selector: 'utax-dispatcher-details',
  templateUrl: './dispatcher-details.component.html',
  styleUrls: ['./dispatcher-details.component.scss']
})
export class DispatcherDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  private _permissions: any[];
  @Input() set permissions(value: any[]) {
    this._permissions = value;
    if (value) {
      this.allowChat = value.some(p => p.name === 'operator_requests_view_chat');
      this.allowKickInChat = value.some(p => p.name === 'operator_chat_remove_member');
      this.permissionOperatorComment = value.some(p => p.name === 'operator_request_discuss');
    }
  }
  get permissions(): any[] {
    return this._permissions;
  }

  public octobusConnection: any;
  selectedOrder: OrderClass;
  shortcuts: ShortcutInput[] = [];
  selectedTabIndex = 0;
  private componentDestroyed$ = new Subject();
  public chatMessageCount: number;
  public allowChat: boolean;
  public allowKickInChat: boolean;
  public permissionOperatorComment: boolean;
  constructor(
    public dispOrdersService: DispOrdersService,
    public orderFormService: OrderFormService,
    public handleEventService: HandleEventService,
    private telephonySharedService: TelephonySharedService,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private ws: WebsocketService,
    private chatApiService: ChatApiService,
    private locationService: LocationService,
    public operatorCommentService: OperatorCommentService,
    public operatorCommentApiService: OperatorCommentApiService
  ) {}

  ngOnInit(): void {
    this.dispOrdersService.selectedOrder$
      .pipe(
        tap((order) => {
          if (this.selectedOrder?.id !== order?.id) {
            this.chatApiService.chatOldMessages$.next([]);
            this.locationService.passengerLocation$.next(null);
            this.locationService.driverLocation$.next(null);
          }
          this.chatApiService.chatNewMessage$.next([]);
        }),
        tap((order: OrderClass) => {
          if (order) {
            if (this.allowChat) {
              if (!this.octobusConnection) {
                this.octobusConnection = this.ws.openConnection('octobus');
                this.octobusConnection.on('updates').subscribe(msg => {
                  if (Array.isArray(msg?.payload)) {
                    const messages = msg.payload.filter(item => {
                      if (item.message_type === 'location') {
                        if (item.user.user_type === 'passenger') {
                          this.locationService.passengerLocation$.next(item.message.location);
                        } else if (item.user.user_type === 'driver') {
                          this.locationService.driverLocation$.next(item.message.location);
                        }
                      }
                      return item.message_type === 'text' ||
                        item.message_type === 'chat_status_updated' && (item.message?.chat_action === 'kick' || item.message?.chat_action === 'left');
                    });
                    if (messages.length) {
                      if (this.chatApiService.currentChatId$.value === messages[0].chat.id) {
                        this.chatApiService.chatNewMessage$.next(messages);
                      } else {
                        this.chatApiService.chatOldMessages$.next(messages);
                      }
                      this.chatApiService.currentChatId$.next(messages[0].chat.id);
                    }
                  } else {
                    if (msg?.payload?.message_type === 'text') {
                      this.chatApiService.chatNewMessage$.next(msg);
                    } else if (msg?.payload?.status === 'error') {
                      if (msg?.payload?.error.includes('user is not in chat')) {
                        this.handleEventService.openSnackBar(`UTAX_${this.chatApiService.lastKick$.value?.toUpperCase()}_IS_NOT_IN_CHAT`);
                      }
                    }
                  }
                });
              }
            }
            if (this.allowChat && !this.selectedOrder && order && order.is_from_app) {
              this.getChatHistory(order.id);
              this.listenChat(order.id);
            } else if (this.allowChat && this.selectedOrder && order && this.selectedOrder.id !== order.id) {
              if (this.selectedOrder.is_from_app) {
                this.listenChat(this.selectedOrder.id, 'off');
              }
              this.locationService.passengerLocation$.next(null);
              this.locationService.driverLocation$.next(null);
              if (order.is_from_app) {
                this.getChatHistory(order.id);
                this.listenChat(order.id);
              }
            }
          }
        }),
        takeUntil(this.componentDestroyed$),
      )
      .subscribe((order: OrderClass) => {
        if (this.selectedOrder?.id !== order?.id && this.permissionOperatorComment) {
          this.getCommentList(order);
        }
        this.selectedOrder = order;
    });
  }

  ngAfterViewInit(): void {
    this.shortcuts.push({
      key: ['tab'],
      command: () => {
        if (this.permissionOperatorComment  && this.selectedTabIndex === 3 ||
          this.selectedTabIndex === 2 && !this.permissionOperatorComment) {
          this.selectedTabIndex = 0;
        } else {
          this.selectedTabIndex++;
        }
      },
      preventDefault: true
    });
  }

  ngOnDestroy(): void {
    if (this.allowChat && this.selectedOrder?.is_from_app) {
      this.listenChat(this.selectedOrder.id, 'off');
    }
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }

  initMap(tabIndex) {
    this.selectedTabIndex = tabIndex;
    if (this.permissionOperatorComment && tabIndex === 3 || tabIndex === 2 && !this.permissionOperatorComment) {
      this.dispOrdersService.selectOrder(this.selectedOrder);
    }
  }

  showSnack($event: boolean) {
    if ($event) {
      this.handleEventService.openSnackBar('UTAX_COPY_LINK_IN_BUFFER_SUCCESS');
    }
  }

  callContactFromOrder(order: Order) {
    this.telephonySharedService.makeCall(order.phone_number, {
      ...order.passenger,
      phone_number: order.phone_number,
      serviceId: order.service_id,
      orderId: order.id
    });
  }

  callContactDeliveryFromOrder(order: Order) {
    this.telephonySharedService.makeCall(order.receiver.phone_number, {
      ...order.passenger,
      phone_number: order.receiver.phone_number,
      serviceId: order.service_id,
      orderId: order.id
    });
  }

  tabChanged($event: any) {
    const elem = document.getElementById('chatContent');
    if (elem) {
      elem.scrollTop = elem.scrollHeight;
    }
  }

  private listenChat(orderId: string, slider: 'on' | 'off' = 'on') {
    const chatConnectionConfig: ChatListenerObjectInterface = {
      message_type: `chat:sniff:${slider}`,
      payload: {
        chat_uuid: orderId
      }
    };
    if (this.authService.authState.value) {
      this.octobusConnection.chatConnect(chatConnectionConfig);
    }
  }

  private getChatHistory(orderId: string) {
    const chatConnectionConfig: ChatListenerObjectInterface = {
      message_type: `get_chat_updates`,
      payload: {
        chat_uuid: orderId
      }
    };
    if (this.authService.authState.value) {
      this.octobusConnection.chatConnect(chatConnectionConfig);
    }
  }



  public chatMessageCounter($event: number) {
    this.chatMessageCount = $event;
    this.cdr.detectChanges();
  }

  sendChatMessage($event: ChatListenerObjectInterface) {
    this.octobusConnection.chatConnect($event);
  }

  private getCommentList(order: OrderClass): void {
    this.operatorCommentService.currentOrderComments$.next([]);
    this.operatorCommentService.currentOrderSelectId$.next(null);
    if (this.permissionOperatorComment && order?.id) {
      this.operatorCommentService.currentOrderSelectId$.next(order.id);
      this.operatorCommentApiService.getOperatorComments(order.id, order.service_id)
        .pipe(
          tap((res) => {
            this.operatorCommentService.currentOrderComments$.next(res.data);
          }),
          untilDestroyed(this)
        )
        .subscribe();
    }
  }
}
