import { AuthService } from './../../../auth/services/auth.service';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'utax-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnChanges {
  @Input('menuList')
  set addMenus(list) {
    if (list) {
      this.menuList = list;
    }
  }
  @Input() permissions: any[];

  menuList: any[];
  archiveLink: string;
  exsistingMenus = [
    'logist',
    'archive',
    'dashboard',
    'configuration/skills',
    'configuration/skill-color',
    'configuration/working-conditions',
    'configuration/driver-registration-link',
    'users',
    'debts',
    'driver-tariffs',
    'car-settings/car-class',
    'telephony',
    'rebinding',
    'users'
  ];

  utaxUrl = `https://qadisp.utaxcloud.net/#/`;
  blackDevUrl = 'https://disp.dev-miranda.megakit.pro/#/';
  telephonyQueuesPermission;
  salariesPermission;
  driverRegistrationPermission;
  driverDeletionPermission;
  driverRegistrationLinkPermission;
  configurationConfigurationsPermission;
  configurationDriverSkillGroupPermission;
  configurationDriverAppManualPermission;
  callQualityStatsPermission;
  debtsPermission;
  managingDispatcherPermission;
  passengerBlacklistPermission;
  findCallCenterItem;
  subMenuCars;
  autocolumn;
  whiteList;
  passengerItem;
  usersItem;
  constructor(private authService: AuthService, private router: Router) {
    this.archiveLink = `${environment.config.whiteFront}archive/`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['permissions'].currentValue) {
      const permissions = changes['permissions'].currentValue;
      const vehicleModel = permissions.find(p => p.name === 'operator_vehicle_model_associations_show');
      const vehicleClass = permissions.find(p => p.name === 'operator_vehicle_class_associations_show');
      this.telephonyQueuesPermission = permissions.find(p => p.name === 'menu_call_center_telephony_queues');
      this.salariesPermission = permissions.find(p => p.name === 'menu_call_center_salaries');
      this.callQualityStatsPermission = permissions.find(p => p.name === 'menu_call_center_call_quality_stats');
      this.driverRegistrationPermission = permissions.find(p => p.name === 'operator_management_driver_registration_view_any');
      this.driverDeletionPermission = permissions.find(p => p.name === 'operator_management_driver_deletion_request_view_any');
      this.debtsPermission = permissions.find(p => p.name === 'operator_passenger_overdraft_record_view_any');
      this.passengerBlacklistPermission = permissions.find(p => p.name === 'operator_call_center_block_passenger_tab_view');
      this.managingDispatcherPermission = permissions.find(p => p.name === 'operator_dispatcher_tab_view' || p.name === 'operator_dispatcher_tab_extended_view' || p.name === 'operator_dispatcher_tab_full_view');
      this.subMenuCars = permissions.find(p => p.name === 'sub_menu_vehicles');
      this.whiteList = permissions.find(p => p.name === 'operator_management_drivers_white_list');
      this.autocolumn = permissions.find(p => p.name === 'operator_management_vehicle_group_show');
      this.passengerItem = permissions.find(p => p.name === 'operator_passenger_statistic_view' || p.name === 'operator_passenger_list_readonly');
      this.driverRegistrationLinkPermission = permissions.find(p => p.name === 'operator_driver_registration_link');
      this.configurationConfigurationsPermission = permissions.find(p => p.name === 'operator_settings_view_any');
      this.configurationDriverSkillGroupPermission = permissions.find(p => p.name === 'operator_driver_skill_group_show');
      this.configurationDriverAppManualPermission = permissions.find(p => p.name === 'operator_driver_app_manual_show_list');
      this.usersItem = permissions.some(p => ['operator_users_view_any', 'operator_users_update', 'operator_roles_view_any', 'operator_roles_update'].includes(p.name));
      const item = this.menuList.find(menuItem => menuItem.name === 'UTAX_FRONT_CAR_SETTINGS');

      if (!item && (vehicleModel || vehicleClass)) {
        this.menuList.push({
          name: 'UTAX_FRONT_CAR_SETTINGS',
          link: 'car-settings/car-class'
        });
      }

      this.findCallCenterItem = this.menuList.find(item => item.name === 'CALL_CENTER');

      if (!this.findCallCenterItem && (this.telephonyQueuesPermission || this.salariesPermission)) {
        this.menuList.splice(1, 0, {
          icon: 'call-center',
          link: 'call-center',
          name: 'CALL_CENTER'
        });
      }
    }
  }

  navigate(link) {
    if (link === this.archiveLink) {
      window.location.href = this.archiveLink;
    } else {
      this.exsistingMenus.indexOf(link) + 1 ? this.router.navigateByUrl(link) : this.naviganeToBlackFront(link);
    }
  }

  logout() {
    this.authService.logout();
  }

  private naviganeToBlackFront(link: string): void {
    window.location.href = environment.production ? this.utaxUrl + link : this.blackDevUrl + link;
  }
}
