import { Injectable } from '@angular/core';
import {BehaviorSubject, forkJoin, Observable, Subject} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Client} from '../../shared/services';
import {pluck} from 'rxjs/operators';
import {DriverVehicleHistoryInterface} from "../../shared/interfaces/history.interface";
import {DriverInfoInterface} from "../drivers/models/drivers.interface";
import {CarClass, ClassAssosiation} from "../applications/models/applications.interface";
import {AssossiationReqInterface} from "../interfaces/driver.interface";
import {IndicatorDriverDataInterface} from "../interfaces/driver-indicators.interface";

@Injectable()
export class DriverService {

  private driverAccountUrl = 'operator/drivers/account';
  private detectReqUrl = 'operator/vehicle-model/associations/detect';
  private detectClassReqUrl = 'operator/vehicle-class/associations/detect';


  public viewDriverBlock = false;
  public driverSearchString$: BehaviorSubject<string> = new BehaviorSubject('');
  public driverSelectedService$: BehaviorSubject<number> = new BehaviorSubject(null);
  public driverSelectedTypeSearch$: BehaviorSubject<'callsign' | 'phone'| 'name'> = new BehaviorSubject('callsign');
  public driversFired$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public driverSearchStrict$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public driversLocked$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public driversVehiclesLengthSource = new BehaviorSubject<number>(0);
  public driverAssosiation$: BehaviorSubject<ClassAssosiation> = new BehaviorSubject(null);
  public driverReqAssosiation$: BehaviorSubject<AssossiationReqInterface> = new BehaviorSubject(null);
  public allowSaving$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public driversVehiclesLength = this.driversVehiclesLengthSource.asObservable();
  private createDriversource = new BehaviorSubject({ id: '', created_at: '' });
  createdDriver = this.createDriversource.asObservable();
  private editDriverSource = new BehaviorSubject({ id: '', created_at: '' });
  editingDriver = this.editDriverSource.asObservable();
  private lockUnlockUserSource = new BehaviorSubject({ id: '', service_id: '', is_blocked: '' });
  lockUnlockUser = this.lockUnlockUserSource.asObservable();
  private blockUserSource = new BehaviorSubject({ id: '', service_id: '' });
  blockingUser = this.blockUserSource.asObservable();
  private driverCommentSource = new BehaviorSubject<string>('');
  driverComment = this.driverCommentSource.asObservable();
  emitBalanceAfterPenalty = new Subject<{ id: string; balance: number }>();

  constructor(private http: HttpClient, private client: Client) {}

  emittDriverComment(comment: string) {
    return this.driverCommentSource.next(comment);
  }

  emitBlockUserEvent(obj) {
    return this.blockUserSource.next(obj);
  }

  emitLockUnlockUser(obj) {
    return this.lockUnlockUserSource.next(obj);
  }
  emitEditingDriver(user) {
    return this.editDriverSource.next(user);
  }

  emitCreatedNewUser(user) {
    return this.createDriversource.next(user);
  }

  driverReplenish(id: string, summ: number, service: number) {
    console.log(arguments);
    return this.http.post(this.client.getDriverReplenishUrl(), {
      id,
      amount: summ,
      service
    });
  }

  driverMinus(id: string, summ: number, service: number) {
    return this.http.post(this.client.getDriverMinusUrl(), {
      id,
      amount: summ,
      service
    });
  }

  getTariffPlansList(obj): Observable<any> {
    return this.http.get(this.client.getTariffPlanListUrl(obj));
  }

  editDriverPin(obj) {
    return this.http.put(this.client.getDriverPinUrl(), obj);
  }

  driverBlock(obj) {
    return this.http.post(this.client.getDriverBlockUrl(), obj);
  }

  driverUnblock(obj) {
    return this.http.post(this.client.getDriverUnblockUrl(), obj);
  }

  getDrivers(obj) {
    // Get
    return this.http.get(this.client.getDriversUrl(), { params: new HttpParams({ fromObject: obj }) });
  }

  getDriversQuery(obj) {
    return this.http.get(this.client.getCabsQuery(obj), { params: new HttpParams({ fromObject: obj }) });
  }

  loadXLS(obj) {
    return this.http.get('operator/driver/download', {
      params: new HttpParams({ fromObject: obj }),
      headers: new HttpHeaders('Content-Type:application/x-www-form-urlencoded'),
      responseType: 'blob'
    });
  }

  getDriver(obj) {
    // Get id of driver
    return this.http.get<any>(this.client.getDriverUrl(obj));
  }

  getDriverNew(obj): Observable<DriverInfoInterface> {
    // Get id of driver
    return this.http.get<DriverInfoInterface>(this.client.getDriverNewUrl(obj)).pipe(pluck('data'));
  }

  getDriverCars(obj) {
    return this.http.get(this.client.getDriverCarsUrl(obj));
  }
  public getDriverCarHistory(id: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('id', id);
    return this.http.get(this.client.getDriverCarHistoryUrl(), { params });
  }

  getDriverReqs(driverId: string, serviceId: number): Observable<any> {
    return forkJoin([
      this.getDriver({
        id: driverId,
        service: serviceId
      }).pipe(pluck('data')),
      this.getDriverCars({
        service: serviceId,
        id: driverId
      }).pipe(pluck('data'))
    ]);
  }

  getBlockHistory(obj) {
    return this.http.get(this.client.getBlockHistoryUrl(obj));
  }

  getDriverFiredReasons(obj) {
    return this.http.get(this.client.getDriverFiredReasonsUrl(obj));
  }

  // getBlockReason(obj) {
  //   return this.http.get(this.client.getDriverBlockReasonUrl(obj));
  // }

  getDriverFire(body) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post(this.client.getDriverFireUrl(), JSON.stringify(body), httpOptions);
  }

  getDriverStatus(obj) {
    return this.http.get(this.client.getDriverStatusesUrl(obj));
  }

  createDriver(obj) {
    console.log('create');
    return this.http.post(this.client.getDriverCreateUrl(), obj);
  }

  editDriver(obj) {
    return this.http.post(this.client.getDriverUpdateUrl(), obj);
  }

  sendLoginCode(obj) {
    return this.http.post(this.client.getDriverSentCodeUrl(obj), {});
  }

  uploadPhoto(obj) {
    return this.http.post(this.client.getUploadImgUrl(), obj);
  }

  getDriverBlockStatus(obj) {
    return this.http.get(this.client.getDriverStatusUrl(obj));
  }

  public emitDriversVehiclesLength(length: number) {
    return this.driversVehiclesLengthSource.next(length);
  }

  public getDriverPinCode(id: string): Observable<any> {
    return this.http.get(this.driverAccountUrl, { params: { id } });
  }

  getClassOfCar(model_id, year, body_type_id): Observable<CarClass> {
    return this.http
      .get<CarClass>(this.detectReqUrl, { params: { model_id, year, body_type_id } })
      .pipe(pluck('data'));
  }

  getInfoAboutCarByClass(params: { service_id: string; vehicle_class_id: string }): Observable<ClassAssosiation> {
    return this.http.get(this.detectClassReqUrl, { params }).pipe(pluck('data'));
  }

  getTemporaryId(service_id): Observable<any> {
    return this.http.post('operator/drivers/temporary', {service_id});
  }

  public getDriverDevices(id: string): Observable<IndicatorDriverDataInterface> {
    return this.http.get<IndicatorDriverDataInterface>('operator/drivers/devices', { params: { id } });

  }

}
