import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { HandleEventService, StateService } from 'src/app/services';
import { Subject, of, forkJoin, Observable } from 'rxjs';
import {takeUntil, mergeMap, tap} from 'rxjs/operators';
import { ShortcutInput } from 'ng-keyboard-shortcuts';
import { MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { HeaderToolsHandlerService } from 'src/app/navigation/services';
import { DriverTariffsService, TariffFormsGenerationService } from '../../services';
import { Tariff } from '../../models/tariff.model';
import { YesNoModalComponent } from 'src/app/shared/components';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { getFormAnObjectToSend } from 'src/app/shared/utils/convert-time-expression';
import { DatePipe } from '@angular/common';
import { PeriodicBonus } from '../../models/bonus.model';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {CommissionReductionDialogComponent, CommissionReductionRemoveDialogComponent} from "../../components";

@UntilDestroy()
@Component({
  selector: 'utax-driver-tariffs',
  templateUrl: './driver-tariffs.component.html',
  styleUrls: ['./driver-tariffs.component.scss']
})
export class DriverTariffsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('controlMenu') controlMenu: TemplateRef<any>;
  selectedServiceId;
  services = [];

  tariffs: Tariff[] = [];
  bonuses: PeriodicBonus[] = [];
  selectedTariff: Tariff;

  shortcuts: ShortcutInput[] = [];

  private selectedTariffIndex = 0;
  private dialogRef: MatDialogRef<YesNoModalComponent>;
  private componentDestroyed$ = new Subject();
  constructor(
    private state: StateService,
    private headerToolsHandlerService: HeaderToolsHandlerService,
    private driverTariffsService: DriverTariffsService,
    protected tariffFormsGenerationService: TariffFormsGenerationService,
    private dialog: MatDialog,
    private handleEventService: HandleEventService,
    private datePipe: DatePipe
  ) {
    this.state.store.pipe(takeUntil(this.componentDestroyed$)).subscribe(store => {
      if (store?.service && store?.service.length > 0) {
        this.services = store.service;
        this.selectedServiceId = this.services[0].id;
        this.updateListOfTariffs(0);
      }
    });
  }

  ngOnInit(): void {
    this.tariffFormsGenerationService.openEditTariffScales$
      .pipe(
        tap(() => this.openCreateEditCommissionScaleDialog('edit')),
      )
      .subscribe();
  }

  ngAfterViewInit() {
    this.headerToolsHandlerService.template.next({
      controlMenu: this.controlMenu,
      source: 'driver-tariffs'
    });
    this.shortcuts.push({
      key: ['ins'],
      command: () => {},
      preventDefault: true
    });
  }

  ngOnDestroy(): void {
    this.tariffFormsGenerationService.selectedTariffScale$.next(null);
    this.tariffFormsGenerationService.tariffs$.next([]);
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }

  selectTariff(tariff, index: number): void {
    this.selectedTariffIndex = index;
    this.selectedTariff = tariff;
    this.driverTariffsService.getPeriodicBonusesList(this.selectedTariff?.id).subscribe(
      bonuses => {
        this.bonuses = bonuses;
      }
    )
    setTimeout(() => {
      this.tariffFormsGenerationService.setScaleIntervals$.next();
    }, 100);
    this.tariffFormsGenerationService.selectedTariffScale$.next(null);
  }

  updateListOfTariffs(selectTariffIndex?: number): void {
    this.tariffFormsGenerationService.selectedTariffScale$.next(null);
    this.driverTariffsService.getListOfTariffsByService(this.selectedServiceId).subscribe((tariffs: Tariff[]) => {
      this.tariffs = tariffs;
      this.tariffFormsGenerationService.tariffs$.next(tariffs);
      if(tariffs.length) {
        this.selectTariff(this.tariffs[0], 0);
      }
      // if (selectTariffIndex !== undefined) {
      //   this.selectTariff(this.tariffs[0], 0);
      // } else {
      //   this.selectTariff(this.tariffs[this.selectedTariffIndex], this.selectedTariffIndex);
      // }
    });
  }

  addNewTariff(): void {
    this.tariffFormsGenerationService.generalForm = this.tariffFormsGenerationService.createGeneralDriverTariffForm();
    this.selectedTariff = this.selectedTariff === null ? undefined : null;
  }

  saveTariff(): void {
    const tariff = this.getValueOfTariffForm();
    if (tariff.id) {
      this.driverTariffsService
        .putTariff(tariff)
        .pipe(
          // mergeMap(this.commissionsSavingFunc),
          mergeMap(this.paymentsSavingFunc),
          mergeMap(this.scalesSavingFunc),
          mergeMap(this.periodicBonusesFunc)
        )
        .subscribe(data => {
          this.handleEventService.openSnackBar('UTAX_FRONT_TARIFF_UPDATE');
          this.updateListOfTariffs();
        });
    } else {
      this.driverTariffsService
        .postTariff(tariff)
        .pipe(
          // mergeMap(this.commissionsSavingFunc),
          mergeMap(this.paymentsSavingFunc),
          mergeMap(this.scalesSavingFunc),
          mergeMap(this.periodicBonusesFunc)
        )
        .subscribe(data => {
          this.handleEventService.openSnackBar('UTAX_FRONT_TARIFF_CREATED');
          this.updateListOfTariffs();
        });
    }
  }

  removeTariff(tariffId: string): void {
    const config = new MatDialogConfig();
    config.data = {
      title: 'UTAX_FRONT_DO_YOU_REALY_WANT_TO_REMOVE_TARIFF?'
    };
    config.panelClass = 'yes-no-modal-container';
    this.dialogRef = this.dialog.open(YesNoModalComponent, config);
    this.dialogRef
      .afterClosed()
      .pipe(
        mergeMap(data => {
          if (data === 'YES') {
            return this.driverTariffsService.deleteTariff(tariffId);
          } else {
            return of();
          }
        })
      )
      .subscribe((res: any) => {
        if (res) {
          this.handleEventService.openSnackBar('UTAX_FRONT_TARIFF_DELETED');
          this.updateListOfTariffs(0);
        }
      });
  }

  clearForm(): void {
    this.tariffFormsGenerationService.generalForm = this.tariffFormsGenerationService.createGeneralDriverTariffForm(
      this.selectedTariff,
      this.bonuses
    );
    setTimeout(() => {
      this.tariffFormsGenerationService.setScaleIntervals$.next();
    }, 100);  }

  private scalesSavingFunc = (res: any) => {
    const scalesValueArray = this.getScaleValueArray(res);
    const tariff = res;
    if (scalesValueArray.length === 0) {
      return forkJoin(of(tariff));
    } else {
      return forkJoin(of(tariff));
    }
  };

  private paymentsSavingFunc = (res: any) => {
    const paymentValueArray = this.getPaymentValues();
    const tariff = res;
    if (paymentValueArray.length === 0) {
      return forkJoin([of(tariff)]);
    } else {
      const reqs: Array<Observable<any>> = [];
      paymentValueArray.forEach((paymentVal, index) => {
        if (paymentVal.id) {
          reqs.push(
            this.driverTariffsService.putRegularPayment({ ...paymentVal, tariff_plan_id: tariff.id }).pipe(
              mergeMap(savedPayment => {
                // create update/create reqs for paticular saved regular payment
                const scaleReqs = [of(true)];
                (paymentVal.scales || []).forEach(scale => {
                  if (scale.id) {
                    scaleReqs.push(this.driverTariffsService.putActionScale(scale));
                  } else {
                    scaleReqs.push(
                      this.driverTariffsService.postActionScale({ ...scale, regular_payment_id: savedPayment.id })
                    );
                  }
                });
                return forkJoin(scaleReqs);
              })
            )
          );
        } else {
          reqs.push(
            this.driverTariffsService.postRegularPayment({ ...paymentVal, tariff_plan_id: tariff.id }).pipe(
              mergeMap(savedPayment => {
                // create create reqs for paticular saved regular payment
                const scaleReqs = [of(true)];
                (paymentVal.scales || []).forEach(scale => {
                  scaleReqs.push(
                    this.driverTariffsService.postActionScale({ ...scale, regular_payment_id: savedPayment.id })
                  );
                });
                return forkJoin(scaleReqs);
              })
            )
          );
        }
      });

      return forkJoin([of(tariff), ...reqs]);
    }
  };

  private commissionsSavingFunc = (tariff: Tariff) => {
    const commissionsValueArrayForSave = this.getCommissionsValue();
    if (commissionsValueArrayForSave.length === 0) {
      return forkJoin([of(tariff)]);
    } else {
      const reqs: Array<Observable<any>> = [];
      commissionsValueArrayForSave.forEach((commissionValue, index) => {
        if (commissionValue.id) {
          reqs.push(this.driverTariffsService.putCommission(commissionValue, tariff.id));
        } else {
          reqs.push(this.driverTariffsService.postCommission(commissionValue, tariff.id));
        }
      });
      return forkJoin([of(tariff), ...reqs]);
    }
  };

  private periodicBonusesFunc = (res: any[]) => {
    const bonuses = this.getPeriodicBonusesValue();
    const tariff = res[0];
    if (bonuses.length === 0) {
      return forkJoin([of(tariff)]);
    } else {
      const reqs: Array<Observable<any>> = [];
      bonuses.forEach((bonus, index) => {
        bonus = this.timeValueSource(bonus);
        bonus.start_time = `${bonus.start_time}:00`;
        bonus.end_time = `${bonus.end_time}:00`;
        if (bonus.id) {
          reqs.push(this.driverTariffsService.putPeriodicBonus(bonus));
        } else {
          reqs.push(this.driverTariffsService.postPeriodicBonus(bonus));
        }
      });
      return forkJoin(reqs);
    }
  }

  private getPeriodicBonusesValue(): any[] {
    const bonusesValueArrayForSave = [];
    this.periodicBonuses.controls.forEach((bonusGroup: UntypedFormGroup) => {
      if (bonusGroup.touched) {
        const bonus = bonusGroup.getRawValue();
        bonusesValueArrayForSave.push({
          ...bonus,
          tariff_plan_id: this.selectedTariff.id,
          include_request_type_all: this.getBoolValue(bonus.type ,'all'),
          include_request_type_automat: this.getBoolValue(bonus.type , 'automat'),
          include_request_type_sending: this.getBoolValue(bonus.type , 'sending'),
          start_time: `${bonus.start_time}`,
          end_time: `${bonus.end_time}`,
          start_date: this.datePipe.transform(bonus.start_date, 'yyyy-MM-dd'),
          end_date: this.datePipe.transform(bonus.end_date, 'yyyy-MM-dd'),
        });
      }
    });
    return bonusesValueArrayForSave;
  }

  private getBoolValue(data: string[], key: string): boolean {
    const res = data && data.find(item => item === key);
    return res ? true : false;
  }

  private getCommissionsValue(): any[] {
    const commissionsValueArrayForSave = [];
    this.commissionsFormArray.controls.forEach((commissionForm: UntypedFormGroup) => {
      if (commissionForm.touched) {
        commissionsValueArrayForSave.push(commissionForm.getRawValue());
      }
    });
    return commissionsValueArrayForSave;
  }

  private getScaleValueArray(resOfPayments: any[]): any[] {
    const scalesValueArray = [];

    this.addRegularPayments.controls.forEach((regularPaymentForm: UntypedFormGroup) => {
      if (regularPaymentForm.touched) {
        (regularPaymentForm.controls.scales as UntypedFormArray).controls.forEach((scale: UntypedFormGroup) => {
          if (scale.touched) {
            const scaleRawValue = scale.getRawValue();
            scalesValueArray.push(scaleRawValue);
          }
        });
      }
    });

    return scalesValueArray;
  }

  private getPaymentValues(): any[] {
    const paymentValueArray = [];

    this.addRegularPayments.controls.forEach((regularPaymentForm: UntypedFormGroup) => {
      if (regularPaymentForm.touched) {
        const rawVal = regularPaymentForm.getRawValue();
        paymentValueArray.push({
          ...rawVal,
          operation: 'add',
          expression: this.getExpression(rawVal)
        });
      }
    });

    this.subRegularPayments.controls.forEach((regularPaymentForm: UntypedFormGroup) => {
      if (regularPaymentForm.touched) {
        const rawVal = regularPaymentForm.getRawValue();
        paymentValueArray.push({
          ...rawVal,
          operation: 'sub',
          expression: this.getExpression(rawVal)
        });
      }
    });

    return paymentValueArray;
  }

  private getValueOfTariffForm(): any {
    const formValue = this.tariffFormsGenerationService.generalForm.getRawValue();
    const newVal = {
      id: formValue.id,
      service_id: this.selectedServiceId,
      // commission tab
      bonus_offer_value: formValue.commission.bonus_offer_value,
      bonus_value: formValue.commission.bonus_value,
      commission_scale_request_type: formValue.commission.commission_scale_request_type,
      commission_scale_request_visibility: formValue.commission.commission_scale_request_visibility,
      commission_scale_type: formValue.commission.commission_scale_type,
      commission_scale_start_day_of_week: formValue.commission.commission_scale_start_day,
      commission_scale_value_type: formValue.commission.commission_scale_value_type,
      debt_limit: formValue.commission.debt_limit,
      ether_min_balance: formValue.commission.ether_min_balance,
      name: formValue.commission.name,
      recommended_balance: formValue.commission.recommended_balance,
      should_block_ether: formValue.commission.should_block_ether,
      use_bonus_value: formValue.commission.use_bonus_value,
      is_additional_commission_scale_enabled: formValue.commission.is_additional_commission_scale_enabled,
      ...formValue.commission.commission,
      ...formValue.commission.distribution,
      ...formValue.commission.intercity,
      ...formValue.commission.commission_app_request,
      ...formValue.commission.hiddenMode,
      ...formValue.commission.hiddenSendingMode,
      ...formValue.commission.commissionCashlessMode,
      // fines tab
      ...formValue.fines.penaltyCardTripCancel,
      ...formValue.fines.penaltyOfferCancel,
      ...formValue.fines.penaltyOfferReject,
      ...formValue.fines.penaltyTripCancel,
      // compensation tab
      ...formValue.compensation
    };
    if (newVal.commission_scale_type === 'time_interval' || newVal.commission_scale_type === 'to_day_end') {
      newVal.commission_scale_intervals = formValue.commission.commission_scale_intervals;
    }
    return newVal;
  }

  private getExpression(obj): any {
    if (obj.period === 'daily') {
      return getFormAnObjectToSend(
        this.timeValueSource({
          days_of_week: obj.days || [],
          bonuses_mode: 'SINGLE_DAYS',
          start_time: obj.start_time,
          end_time: obj.end_time
        })
      ).expression;
    } else {
      return getFormAnObjectToSend(
        this.timeValueSource({
          bonuses_mode: 'CONTINUOUS_DAYS',
          interval_type: obj?.interval_type,
          start_time: obj.start_time,
          end_time: obj.end_time,
          start_day: obj.start_day,
          end_day: obj.end_day
        })
      ).expression;
    }
  }

  private timeValueSource(obj) {
    if (!((obj.start_time || '').indexOf(':') + 1)) {
      obj.start_time = obj.start_time.slice(0, 2) + ':' + obj.start_time.slice(2);
    }
    if (!((obj.end_time || '').indexOf(':') + 1)) {
      obj.end_time = obj.end_time.slice(0, 2) + ':' + obj.end_time.slice(2);
    }
    return obj;
  }

  private get subRegularPayments(): UntypedFormArray {
    return (this.tariffFormsGenerationService.generalForm.controls.subRegularPayments as UntypedFormGroup).controls
      .regular_payments as UntypedFormArray;
  }

  private get addRegularPayments(): UntypedFormArray {
    return (this.tariffFormsGenerationService.generalForm.controls.addRegularPayments as UntypedFormGroup).controls
      .regular_payments as UntypedFormArray;
  }

  private get periodicBonuses(): UntypedFormArray {
    return (this.tariffFormsGenerationService.generalForm.controls.periodicBonuses as UntypedFormGroup).controls
      .bonuses as UntypedFormArray;
  }

  private get commissionsFormArray(): UntypedFormArray {
    return (this.tariffFormsGenerationService.generalForm.controls.commission as UntypedFormGroup).controls
      .commissions as UntypedFormArray;
  }

  public openCreateEditCommissionScaleDialog(type: 'edit' | 'create'): void {
    const config = new MatDialogConfig();
    config.data = type === 'edit' ? this.tariffFormsGenerationService.selectedTariffScale$.value : null;
    config.panelClass = 'request-activate-dialog-container';
    config.backdropClass = 'request-dialog-backdrop';
    config.width =  '1385px';
    this.dialog.open(CommissionReductionDialogComponent, config)
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((el) => {
        if (el) {
          this.tariffFormsGenerationService.updateCreateTariffScale$.next(el);
        }
      });
  }
  public openRemoveCommissionScaleDialog(): void {
    this.dialog.open(CommissionReductionRemoveDialogComponent, {
      panelClass: 'request-activate-dialog-container',
      backdropClass: 'request-dialog-backdrop',
      width: '442px',
      disableClose: true,
      data: {
        id: this.tariffFormsGenerationService.selectedTariffScale$.value.id,
      }
    })
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((el) => {
        if (el) {
          this.tariffFormsGenerationService.removeScale$.next(el);
        }
      });
  }
}
