import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import * as fromContainers from './containers';
import * as fromModules from './modules';

import { MaterialModule } from '../utils/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { Routes, RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { AuthGuard } from '../utils/auth.guard';
import { RemoteWorkGuard } from '../utils/remote-work.guard';
import {DispSettingGuard} from "./modules/disp-settings/guards/disp-setting.guard";

const ROUTES: Routes = [
  {
    path: '',
    component: fromContainers.CallCentreComponent,
    children: [
      {
        path: '',
        redirectTo: 'disp-settings',
        pathMatch: 'full'
      },
      {
        path: 'queue',
        loadChildren: () => import('./modules/queue/queue.module').then(m => m.QueueModule),
        canActivate: [AuthGuard, RemoteWorkGuard]
      },
      {
        path: 'disp-settings',
        loadChildren: () => import('./modules/disp-settings/disp-settings.module').then(m => m.DispSettingsModule),
        canActivate: [AuthGuard, RemoteWorkGuard, DispSettingGuard]
      },
      {
        path: 'disp-salary',
        loadChildren: () => import('./modules/disp-salary/disp-salary.module').then(m => m.DispSalaryModule),
        canActivate: [AuthGuard, RemoteWorkGuard]
      },
      {
        path: 'salary-modifier',
        loadChildren: () => import('./modules/salary-modifier/salary-modifier.module').then(m => m.SalaryModifierModule),
        canActivate: [AuthGuard, RemoteWorkGuard]
      },
      {
        path: 'reports',
        loadChildren: () => import('./modules/reports/repots.module').then(m => m.RepotsModule),
        canActivate: [AuthGuard, RemoteWorkGuard]
      },
      {
        path: 'passenger-blacklist',
        loadChildren: () => import('./modules/passenger-blacklist/passenger-blacklist.module').then(m => m.PassengerBlacklistModule),
        canActivate: [AuthGuard, RemoteWorkGuard]
      }
    ]
  }
];

@NgModule({
  declarations: [...fromContainers.containers],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    SharedModule,
    ReactiveFormsModule,
    KeyboardShortcutsModule,
    FormsModule,
    ...fromModules.modules,
    RouterModule.forChild(ROUTES),
    NgxMaskModule.forChild()
  ]
})
export class CallCentreModule {}
