import { TariffPipe } from './tariff.pipe';
import { DistancePipe } from './distance.pipe';
import { GetDiffInTimePipe } from './get-diff-in-time.pipe';
import { ChangePlaceCurrencyPipe } from './change-place-currency.pipe';
import { GetEndTimeOfTripPipe } from './get-end-time-of-trip.pipe';
import { GetServiceNamePipe } from './get-service-name.pipe';
import { ShortPhoneNumberPipe } from './phone-short.pipe';
import { LocTzPipe } from './loc-tz.pipe';
import { TimeAtPipe } from './time-at.pipe';
import { LocalizedTimePipe } from './localized-time.pipe';
import { LocalizedDatePipe } from './localized-date.pipe';
import { GetWaypointPipe } from './get-waypoint.pipe';
import { ClusterSortPipe } from './cluster-sort.pipe';
import { GetProductNamePipe } from './get-product-name.pipe';
import { SortByFieldPipe } from './sort-by-field.pipe';
import { SortByPredicatePipe } from './sort-by-predicate.pipe';
import { FilterPipe } from './filter.pipe';
import { CustomTranslatePipe } from './custom-translate.pipe';
import { EnumToArrayPipe } from './enum-to-array.pipe';
import { GetDiffInTimeBetweenPipe } from './get-diff-in-time-between.pipe';
import { CapitalizePipe } from './capitalize.pipe';
import {LocTimePipe} from "./loc-time.pipe";
import {UrlifyPipe} from "./urlify.pipe";
import {SafeHtmlPipe} from "./safeHTML.pipe";

export const pipes: any[] = [
  TimeAtPipe,
  LocTzPipe,
  LocalizedTimePipe,
  LocalizedDatePipe,
  ShortPhoneNumberPipe,
  GetWaypointPipe,
  GetServiceNamePipe,
  GetEndTimeOfTripPipe,
  ChangePlaceCurrencyPipe,
  GetDiffInTimePipe,
  DistancePipe,
  TariffPipe,
  ClusterSortPipe,
  GetProductNamePipe,
  SortByFieldPipe,
  SortByPredicatePipe,
  FilterPipe,
  CustomTranslatePipe,
  EnumToArrayPipe,
  GetDiffInTimeBetweenPipe,
  CapitalizePipe,
  LocTimePipe,
  UrlifyPipe,
  SafeHtmlPipe
];

export * from './capitalize.pipe';
export * from './get-diff-in-time-between.pipe';
export * from './filter.pipe';
export * from './sort-by-field.pipe';
export * from './sort-by-predicate.pipe';
export * from './custom-translate.pipe';
export * from './get-product-name.pipe';
export * from './cluster-sort.pipe';
export * from './tariff.pipe';
export * from './distance.pipe';
export * from './get-diff-in-time.pipe';
export * from './change-place-currency.pipe';
export * from './get-end-time-of-trip.pipe';
export * from './get-service-name.pipe';
export * from './get-waypoint.pipe';
export * from './phone-short.pipe';
export * from './loc-tz.pipe';
export * from './time-at.pipe';
export * from './localized-time.pipe';
export * from './localized-date.pipe';
export * from './enum-to-array.pipe';
export * from './loc-time.pipe';
export * from './urlify.pipe';
export * from './safeHTML.pipe';
