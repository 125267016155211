export interface FallbackStatusesInfoModel {
  total: number;
  waiting: number;
  answered: number;
  incoming: number;
  outgoing: number;
  incomingWaiting: number;
  outgoingWaiting: number;
}

export interface IOperatorStatistic {
  mobileOperator: IMobileOperator;
  lastTimeCall: string;
  deltaSecond: number;
}

export interface IMobileOperator {
  id: string;
  name: string;
  displayName: string;
}

export interface StatusesInfoModel {
  total: number;
  remotely: number;
  active: number;
  paused: number;
  receiveCallsFromDrivers: number;
  receiveCallsFromPassengers: number;
  receiveCallsFromNewClients: number;
  brigades: IBrigade[];
  // free: number;
  // all_operators: number;
  // receive_calls_from_drivers: number;
  // receive_calls_from_passengers: number;
  // walked_away: number;
  // work_at_home: number;
}

export interface MissedCalls {
  missed_calls_count: number;
  queue_id: string;
}

export interface ICallsStatisticRealtime {
  taxiServiceId: string;
  answeredFromCompanyDrivers: number;
  answeredFromDrivers: number;
  answeredFromNewClients: number;
  answeredFromPassengers: number;
  missedFromCompanyDrivers: number;
  missedFromDrivers: number;
  missedFromNewClients: number;
  missedFromPassengers: number;
  recentFromCompanyDrivers: number;
  recentFromDrivers: number;
  recentFromNewClients: number;
  recentFromPassengers: number;
  recentTotalWaitDurationFromCompanyDrivers: number;
  recentTotalWaitDurationFromDrivers: number;
  recentTotalWaitDurationFromNewClients: number;
  recentTotalWaitDurationFromPassengers: number;
  waitingFromCompanyDrivers: number;
  waitingFromDrivers: number;
  waitingFromNewClients: number;
  waitingFromPassengers: number;
}

export interface IOperatorStatisticTaxiServiceCounters {
  taxiServiceId: string;
  total: number;
  remotely: number;
  active: number;
  activeRemotely: number;
  paused: number;
  pausedRemotely: number;
  receiveCallsFromDrivers: number;
  receiveCallsFromPassengers: number;
  receiveCallsFromNewClients: number;
  talkWithDrivers: number;
  talkWithPassengers: number;
  talkWithNewClients: number;
}

export interface QueueCallsInfoModel {
  calls_from_drivers_at_moment: number;
  calls_from_new_clients_at_moment: number;
  calls_from_passengers_at_moment: number;
  in_work_count: number;
  queue_service_group_id: string;
  queue_service_group_name: string;
  remote_count: number;
  talk_with_drivers: number;
  talk_with_new_clients: number;
  talk_with_passengers: number;
  receive_calls_from_drivers: number;
  receive_calls_from_new_clients: number;
  receive_calls_from_passengers: number;
  missed_calls_from_new_clients: number;
  missed_calls_from_passengers: number;
  unanswered_calls_from_new_clients: number;
  unanswered_calls_from_passengers: number;
  missed_calls_from_drivers: number;
  unanswered_calls_from_drivers: number;
}

export interface DispatcherModel {
  allow_calls_from_drivers: boolean;
  allow_calls_from_new_clients: boolean;
  allow_calls_from_passengers: boolean;
  brigade: number;
  created_at: number;
  email: string;
  external_related_id: string;
  callLineIds: string[];
  global_user_name: string;
  groups: DispRole[];
  name: string;
  status: any;
  phone_number: string;
  queue_service_groups: QueueServiceGroupModel[];
  call_status: DispCallStatus;
  work_status: DispatcherWorkStatus;
  id: string;
  externalId: string;
  displayName: string;
  categoryId: string;
  brigadeId: string;
  phoneNumber: string;
  roleIds: string[];
  taxiServices: any[];
  category: DispCategory;
  remote: boolean;
  taxiServiceIds: string[];
  activeTaxiServiceIds: string[];
  // front
  loading?: boolean;
  activeCallLines?: any;
  hideActivationAllServices?: boolean;
  state?: 'none' | 'creating' | 'editing' | 'viewing';

}

export interface QueueServiceGroupModel {
  active: boolean;
  name: string;
  id: any;
}

export enum DispatcherWorkStatus {
  ACTIVE = 'ACTIVE',
  FINISH_WORKING = 'FINISH_WORKING',
  TALKING = 'TALKING',
  PAUSED = 'PAUSED',
  INACTIVE = 'INACTIVE',
  REMOTELY = 'REMOTELY',
  REMOTE_WORK = 'REMOTE_WORK',
  WALKED_AWAY = 'WALKED_AWAY'
}

export enum DispCallStatus {
  FREE = 'FREE',
  SYSTEM_TALK = 'SYSTEM_TALK',
  TALKING = 'TALKING',
  DIALED = 'DIALED',
  TALK = 'TALK',
  UNAVAILABLE = 'UNAVAILABLE'
}

export enum DispCategory {
  JUNIOR = 'JUNIOR',
  REGULAR = 'REGULAR',
  SENIOR = 'SENIOR'
}

export enum DispRemoteWorkReq {
  APPROVE = 'APPROVE',
  NEW = 'NEW',
  REJECT = 'REJECT'
}

export interface PutBodyDispCallSettings {
  allow_calls_from_drivers: boolean;
  allow_calls_from_new_clients: boolean;
  allow_calls_from_new_clients_global: boolean;
  auto_answer: boolean;
  queue_service_group_ids: string[];
  user_uid: string;
}

export interface DispCaterogy {
  id: string;
  name: string;
  type: string;
}

export interface IBrigade {
  id: string;
  name: string;
  type: string;
  total?: number;
}

export interface IActiveTaxiServiceIds {
  activeTaxiServiceIds: string[];
}

export interface DispRole {
  default: boolean;
  loc_name: string;
  name: string;
  isRestricted: boolean;
  id: string;
}

export enum RemoteRequestState {
  APPROVE = 'APPROVE',
  NEW = 'NEW',
  REJECT = 'REJECT'
}

export interface RemoteRequestRespModel {
  created_at: number;
  decider_id: string;
  state: RemoteRequestState;
  updated_at: number;
  user: DispatcherModel;
  uuid: string;
}

export enum HistoryChange {
  OPERATOR_UPDATE_TAXI_SERVICES = 'OPERATOR_UPDATE_TAXI_SERVICES',
  ACCEPT_REQUEST_FOR_WORK_AT_HOME = 'ACCEPT_REQUEST_FOR_WORK_AT_HOME',
  CHANGE_ACTIVE_QUEUE_SERVICE_GROUPS = 'CHANGE_ACTIVE_QUEUE_SERVICE_GROUPS',
  CHANGE_AUTO_ANSWER_ABILITY = 'CHANGE_AUTO_ANSWER_ABILITY',
  CHANGE_AVAILABLE_QUEUE_SERVICE_GROUPS = 'CHANGE_AVAILABLE_QUEUE_SERVICE_GROUPS',
  CHANGE_BRIGADE = 'CHANGE_BRIGADE',
  CHANGE_CALL_RULES = 'CHANGE_CALL_RULES',
  CHANGE_CATEGORY = 'CHANGE_CATEGORY',
  CHANGE_EMAIL = 'CHANGE_EMAIL',
  CHANGE_GROUPS = 'CHANGE_GROUPS',
  CHANGE_NAME = 'CHANGE_NAME',
  CHANGE_PHONE_NUMBER = 'CHANGE_PHONE_NUMBER',
  CHANGE_WORK_SHIFT = 'CHANGE_WORK_SHIFT',
  OPERATOR_CREATE = 'OPERATOR_CREATE',
  DISABLE_WORK_AT_HOME_ABILITY = 'DISABLE_WORK_AT_HOME_ABILITY',
  REJECT_REQUEST_FOR_WORK_AT_HOME = 'REJECT_REQUEST_FOR_WORK_AT_HOME',
  REQUEST_FOR_WORK_AT_HOME = 'REQUEST_FOR_WORK_AT_HOME'
}

export interface DispHistoryReqordModel {
  change_type: HistoryChange;
  created_at: number;
  from_state: { value: string[] };
  initiator_global_user_name: string;
  initiator_name: string;
  localized_description: string;
  to_state: { value: string[] };
  user_uid: string;
  uuid: string;
}

export interface DispatcherFilterModel {
  type: DispatcherFilterType;
  value: any;
}

export enum CheckboxType {
  ALLOW_CALLS_FROM_DRIVERS = 'ALLOW_CALLS_FROM_DRIVERS',
  ALLOW_CALLS_FROM_COMPANY_DRIVERS = 'ALLOW_CALLS_FROM_COMPANY_DRIVERS',
  ALLOW_CALLS_FROM_NEW_CLIENTS = 'ALLOW_CALLS_FROM_NEW_CLIENTS',
  ALLOW_CALLS_FROM_PASSENGERS = 'ALLOW_CALLS_FROM_PASSENGERS'
}

export enum DispatcherCallRuleType {
  ALLOW_CALLS_FROM_PASSENGERS = 'allow_calls_from_passengers',
  ALLOW_CALLS_FROM_NEW_CLIENTS = 'allow_calls_from_new_clients',
  ALLOW_CALLS_FROM_DRIVERS = 'allow_calls_from_drivers',
  ALLOW_CALLS_FROM_COMPANY_DRIVERS = 'allow_calls_from_company_drivers',
}

export enum DispatcherFilterType {
  BRIGADE = 'brigadeId',
  GLOBAL_USER_NAME = 'GLOBAL_USER_NAME',
  NAME = 'query',
  QUEUE_GROUPS = 'taxiServiceId',
  ROLES = 'roleId'
}

export interface IAddMultiQueueGroupQuery {
  override?: boolean;
  strict?: boolean;
  strict_unbind?: boolean;
}

export interface IOperatorActivityRequest {
  ids: string[];
}

export interface IOperatorActivityResponse {
  data: IOperatorActivity[];
}

export interface IOperatorActivity {
  id: string;
  subject_type: string;
  subject_id: string;
  state: 'none' | 'creating' | 'editing' | 'viewing';
}

export  interface ICardBilling {
  uuid: string;
  is_default: boolean;
  is_active: boolean;
  is_disabled: boolean;
  title: string;
  type: string;
  masked_card?: string;
  valid_till?: string;
}
