import { QueueServiceModel } from '../../call-centre/modules/queue/models';

export interface BasicUserModel {
  email: string;
  global_user_name: string;
  name: string;
  phone_number: string;
  uuid: string;
}

export interface UserModel extends BasicUserModel {
  brigade: number;
  category: UserCategory;
  created_at: number;
  groups: string[];
  queue_service_groups: QueueServiceModel[];
  remote: true;
  uuid: string;
  work_status: UserWorkStatus;
}

export enum UserCategory {
  JUNIOR = 'JUNIOR',
  REGULAR = 'REGULAR',
  SENIOR = 'SENIOR',
  PRIORITIZED = 'PRIORITIZED'
}

export enum UserWorkStatus {
  ACTIVE = 'ACTIVE',
  FINISH_WORKING = 'FINISH_WORKING',
  INACTIVE = 'INACTIVE',
  REMOTE_WORK = 'REMOTE_WORK',
  WALKED_AWAY = 'WALKED_AWAY'
}

export interface UserModelNew {
  id: string;
  brigadeId: string;
  categoryId: string;
  roleIds: string[];
  name: string;
  displayName: string;
  phoneNumber: string;
  email: string;
}

export interface IKeyboardSelect {
  name: string;
  value: 'old' | 'new';
}
